import { Bifrost, TENANT_PLATFORM } from '@hiver/bifrost-js';
import { Hiver_New_Auth_Base_Url } from '../Constants/ApiRoutes';

const bifrostConfig = {
  userId: '',
  userGroupId: '',
  baseUrl: '',
  appEnv: '',
  tier: '',
  bifrost: null,
  isInitiated: false,
  isInternal: false
};

export const initConnectorsBifrost = ({
  appEnv,
  tier,
  userId,
  userGroupId,
  tenantPlatform = TENANT_PLATFORM.GMAIL,
  baseUrl = Hiver_New_Auth_Base_Url,
  withCredentials = true,
  getTokenForLogin,
}) => {
  bifrostConfig.baseUrl = baseUrl;
  bifrostConfig.appEnv = appEnv;
  bifrostConfig.tier = tier;
  bifrostConfig.userGroupId = userGroupId;
  bifrostConfig.userId = userId;
  bifrostConfig.tenantPlatform = tenantPlatform;
  bifrostConfig.bifrost = new Bifrost(appEnv, tier, tenantPlatform, getTokenForLogin, withCredentials);
  bifrostConfig.isInitiated = true;
};

const HiverAuthCall = ({ configHiver, withCredentials }) => {
  const makeNewAuthAxiosCall = async (inputs) => {
    if( !bifrostConfig.isInitiated ){
      throw new Error("Please initiate bifrost config before calling apis");
    }
    const { url, method, getToken = true, noToken = withCredentials } = inputs;

    const requestParams = {
      root_url: bifrostConfig.baseUrl,
      url,
      method,
      withCredentials: noToken,
      headers: inputs?.headers,
      csrfToken: inputs?.csrfToken,
      onSuccess: inputs?.onSuccess,
      onFail: inputs?.onFail,
      data: inputs?.data,
      params: inputs?.params,
      timeout: inputs?.timeout,
      formData: inputs?.formData,
      userId: Number(bifrostConfig.userId),
      usergroupId: Number(bifrostConfig.userGroupId)
    };
    try {
      console.log(
        '--- new requestParams - ',
        requestParams,
        ' --- configHiver - ',
        configHiver,
        ' --- isHiverApiDomain - ',
        true
      );
      const response = await bifrostConfig.bifrost.hiverAxios(requestParams, { ...configHiver, ...({ getToken }) });
      return response;
    } catch (error) {
      console.error('API call failed with error: ', error);
      const errorObj = {
        ...error,
        ...(error?.response && { ...error?.response }),
        ...(error?.request && { ...error.request }),
      };
      throw errorObj;
    }
  };

  return makeNewAuthAxiosCall;
};

const HiverKbBifrost = HiverAuthCall({
  configHiver: {
    fetchCsrfToken: () => '',
    isHiverApiDomain: true
  },
  withCredentials: true
});

export { HiverAuthCall, HiverKbBifrost };
